import {getStoryblokApi, SbBlokData, StoryblokComponent, storyblokEditable} from "@storyblok/react";
import classNames from "classnames";

type IProps = {
    blok: {
        title: string,
        text: any,
        body: Array<SbBlokData>,
        left: Array<SbBlokData>,
        container_size: string,
        container_padding: string,
        container_class: string
        col1_class: string,
        col2_class: string
    } & SbBlokData
}

export default function TwoColumnOffset(props: IProps) {
    const storyblokApi = getStoryblokApi();
    const blok = props.blok;

    return <div
        className={classNames("relative bg-white", blok.container_padding || 'py-4 sm:py-16 lg:py-16')}  {...storyblokEditable(blok)}>
        <div
            className={"mx-auto grid gap-4 sm:gap-4 sm:items-start lg:gap-8 sm:grid-cols-2 lg:items-start px-4 lg:px-8 " + blok.container_class + " " + (blok.container_size || 'max-w-7xl')}>

            <div className={'col-span-1 h-full ' + blok.col1_class}>
                <div className={'sticky top-36'}>
                    {blok.left?.map((nestedBlok: any) => (
                        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid}/>
                    ))}
                </div>
            </div>

            <div className={"col-span-1 relative text-gray-800 rich-text text-justify " + blok.col2_class}>
                {blok.body?.map((nestedBlok: any) => (
                    <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid}/>
                ))}
            </div>

        </div>

    </div>
}